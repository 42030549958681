import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

function AuthForm() {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  useEffect(() => {
    window.google?.accounts.id.initialize({ client_id: clientId, callback: handleCredentialResponse });
    window.google?.accounts.id.renderButton(document.getElementById('signInDiv'), { theme: 'outline', size: 'large' });
    window.google?.accounts.id.prompt();
  }, [clientId]);

  const handleCredentialResponse = (response) => {
    setIsLoading(true);
    axios.post(`${apiUrl}/auth/verifyGoogleToken`, { token: response.credential })
      .then(res => {
        if (res.data && res.data.jwtToken) {
          setEmail(res.data.email || ''); // Ensure email is never undefined
          login(res.data, navigate);
          setMessage('Google login successful. Redirecting...');
          //navigate('/OrdersPage');
        } else {
          throw new Error('Invalid response from server');
        }
      })
      .catch(error => {
        console.error('Verification error:', error);
        setMessage(`Failed to verify Google login: ${error.message || 'Unknown error'}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const requestOtp = async () => {
    setIsLoading(true);
    try {
      const res = await axios.post(`${apiUrl}/api/otp/sendMessage`, { email });
      setMessage('OTP has been sent.');
      setStep(2);
    } catch (error) {
      setMessage('Failed to send OTP. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const verifyOtp = async () => {
    setIsLoading(true);
    try {
      const res = await axios.post(`${apiUrl}/api/otp/verifyMessage`, { email, otp });
      if (res.data) {
        login(res.data);
        setMessage('OTP verified successfully. You are now logged in.');
        navigate('/OrdersPage');
      } else {
        setMessage('OTP verified but login failed, please try again.');
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleError = (error) => {
    if (error.response) {
      setMessage(`Error: ${error.response.data.message}`);
    } else if (error.request) {
      setMessage("Error: No response from server");
    } else {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div>
      {step === 1 && (
        <>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            aria-label="Email"
          />
          <button onClick={requestOtp} disabled={!email || isLoading}>
            {isLoading ? 'Sending...' : 'Send OTP'}
          </button>
          <div id="signInDiv"></div> {/* Google Sign-in button will be rendered here */}
        </>
      )}
      {step === 2 && (
        <>
          <input
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            placeholder="Enter OTP"
            aria-label="OTP"
          />
          <button onClick={verifyOtp} disabled={!otp || isLoading}>
            {isLoading ? 'Verifying...' : 'Verify OTP'}
          </button>
        </>
      )}
      <p>{message}</p>
    </div>
  );
}

export default AuthForm;
