import React, { useState } from 'react';
import axios from 'axios';
import './RegistrationForm.css';

function RegistrationForm() {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        otp: '',
        otpSent: false
    });
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL; // Ensure your API URL is correct

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSendOTP = async () => {
        try {
            const response = await axios.post(`${apiUrl}/otp/send`, { phoneNumber: formData.phoneNumber });
            setFormData({ ...formData, otpSent: true });
            setMessage('OTP sent to your phone.');
        } catch (error) {
            setMessage('Failed to send OTP.');
            console.error('OTP Error:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.password !== formData.confirmPassword) {
            setErrors({ confirmPassword: 'Passwords do not match' });
            return;
        }
        try {
            const response = await axios.post(`${apiUrl}/users/register`, { ...formData });
            setMessage('User registered successfully. Please login.');
        } catch (error) {
            setMessage('Registration failed.');
            console.error('Registration Error:', error);
        }
    };

    return (
        <div className="registration-form">
            <h1>Register</h1>
            {message && <p>{message}</p>}
            <form onSubmit={handleSubmit}>
                <input type="text" name="username" placeholder="Username" onChange={handleChange} required />
                <input type="email" name="email" placeholder="Email" onChange={handleChange} required />
                <input type="tel" name="phoneNumber" placeholder="Phone Number" onChange={handleChange} required />
                {formData.otpSent ? (
                    <>
                        <input type="text" name="otp" placeholder="Enter OTP" onChange={handleChange} required />
                        <button type="submit">Verify & Register</button>
                    </>
                ) : (
                    <button type="button" onClick={handleSendOTP}>Send OTP</button>
                )}
                <input type="password" name="password" placeholder="Password" onChange={handleChange} required />
                <input type="password" name="confirmPassword" placeholder="Confirm Password" onChange={handleChange} required />
            </form>
            {errors.confirmPassword && <p>{errors.confirmPassword}</p>}
        </div>
    );
}

export default RegistrationForm;
