import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';
import styles from './MenuViewer1.module.css';
import AuthContext from '../context/AuthContext';
import Modal from './Modal'; // Assuming you have a Modal component
import { Stomp } from '@stomp/stompjs'; // Correct import for Stomp
import bellSound from '../assets/copper-bell-ding-4-204990.mp3';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function MenuViewer1() {
    const navigate = useNavigate();
    const { token, user } = useContext(AuthContext);
    const [menuItems, setMenuItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState(JSON.parse(localStorage.getItem('selectedItems')) || []);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [category, setCategory] = useState('All');
    const [showModal, setShowModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;
    const audioRef = useRef(new Audio(bellSound));

    const query = useQuery();
    const businessProfileId = query.get('businessProfileId');
    const tableNumber = query.get('tableNumber');

    useEffect(() => {
        if (businessProfileId && tableNumber) {
            localStorage.setItem('tableNumber', tableNumber);
            localStorage.setItem('businessProfileId', businessProfileId);
            loadMenuItems(businessProfileId);
        } else {
            setError("Missing essential URL parameters.");
        }
    }, [businessProfileId, tableNumber]);

    useEffect(() => {
        filterMenuItemsByCategory(category);
    }, [category, menuItems]);

    const loadMenuItems = async (businessProfileId) => {
        try {
            const response = await axios.get(`${apiUrl}/api/menus`, {
                params: { businessProfileId },
                headers: { Authorization: `Bearer ${token}` }
            });
            setMenuItems(response.data);
            setFilteredItems(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching menu items:', error);
            setError('Failed to load menu items');
            setLoading(false);
        }
    };

    const handleAddToOrder = (item) => {
        const existingItem = selectedItems.find((i) => i.id === item.id);
        if (existingItem) {
            setSelectedItems((prevItems) =>
                prevItems.map((i) =>
                    i.id === item.id ? { ...i, quantity: i.quantity + 1 } : i
                )
            );
        } else {
            setSelectedItems((prevItems) => [...prevItems, { ...item, quantity: 1 }]);
        }
    };

    const handleRemoveFromOrder = (id) => {
        const item = selectedItems.find((i) => i.id === id);
        if (item.quantity > 1) {
            setSelectedItems((prevItems) =>
                prevItems.map((i) =>
                    i.id === id ? { ...i, quantity: i.quantity - 1 } : i
                )
            );
        } else {
            setSelectedItems((prevItems) => prevItems.filter((item) => item.id !== id));
        }
    };

    const calculateTotalAmount = () => {
        return selectedItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
    };

    const handlePlaceOrder = () => {
        if (!user) {
            localStorage.setItem('pendingOrder', JSON.stringify({ businessProfileId, items: selectedItems, tableNumber }));
            navigate('/login');
            return;
        }

        const orderDetails = {
            userId: user.id,
            businessProfileId,
            tableNumber,
            items: selectedItems.map((item) => ({
                productId: item.id,
                quantity: item.quantity,
                price: item.price
            }))
        };

        axios.post(`${apiUrl}/api/orders`, orderDetails, {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then((response) => {
                console.log('Order placed:', response.data);
                setSelectedItems([]);
                localStorage.removeItem('selectedItems');
                setSuccessMessage('Order placed successfully!');
                setTimeout(() => setSuccessMessage(''), 5000);
            })
            .catch((error) => {
                console.error('Error placing order:', error.response);
                setError('Failed to place order');
            });
    };

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    };

    const filterMenuItemsByCategory = (category) => {
        if (category === 'All') {
            setFilteredItems(menuItems);
        } else {
            setFilteredItems(menuItems.filter((item) => item.category === category));
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className={styles.menuContainer}>
            <h1 className={styles.menuHeader}>Menu</h1>
            <div className={styles.categorySelector}>
                <label htmlFor="category" className={styles.categoryLabel}>Filter by Meal:</label>
                <select
                    id="category"
                    value={category}
                    onChange={handleCategoryChange}
                    className={styles.categorySelect}
                >
                    <option value="All">All</option>
                    <option value="Breakfast">Breakfast</option>
                    <option value="Lunch">Lunch</option>
                    <option value="Dinner">Dinner</option>
                    <option value="Other">Other</option>
                </select>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.menuItemsSection}>
                    <ul className={styles.menuList}>
                        {filteredItems.map((item) => (
                            <li key={item.id} className={item.available ? styles.menuItem : `${styles.menuItem} ${styles.disabledItem}`}>
                                <span className={styles.menuName}>{item.name}</span>
                                <span className={styles.menuPrice}>{item.price.toFixed(2)}</span>
                                <button
                                    onClick={() => item.available && handleAddToOrder(item)}
                                    disabled={!item.available}
                                    className={styles.smallButton}
                                >
                                    Add
                                </button>
                            </li>
                        ))}
                    </ul>

                </div>
                <div className={styles.orderSection}>
                    <h2 className={styles.orderTitle}>Your Order</h2>
                    {selectedItems.length > 0 ? (
                        <ul className={styles.orderList}>
                            {selectedItems.map((item) => (
                                <li key={item.id} className={styles.orderItem}>
                                    <span className={styles.menuName}>{item.name}</span>
                                    <span className={styles.menuPrice}>
                                        {item.price.toFixed(2)} x {item.quantity}
                                    </span>
                                    <button onClick={() => handleRemoveFromOrder(item.id)} className={styles.removeButton}>Remove</button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className={styles.emptyOrderMessage}>Your order is empty</p>
                    )}
                </div>
            </div>
            <div className={styles.orderFooter}>
                <span className={styles.totalAmountLabel}>Total Order Amount:</span>
                <span className={styles.totalAmount}>{`₹${calculateTotalAmount()}`}</span>
                <button onClick={handlePlaceOrder} disabled={!selectedItems.length} className={styles.placeOrderButton}>
                    Place Order
                </button>
            </div>
            {successMessage && (
                <div className={styles.successMessage}>
                    {successMessage}
                </div>
            )}
        </div>
    );
}

export default MenuViewer1;
