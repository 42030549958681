import React, { useEffect, useState } from 'react';
import { useWebSocket } from '../context/WebSocketContext';
import Modal from './Modal';
import paymentLogo from '../assets/upi.jpeg';  // A generic payment logo for all types of payments
import upiLogo from '../assets/upi.jpeg';  // Add a UPI-specific logo in your assets if available

function NotificationDisplay() {
    const { paymentLink, waiterCall, showModal, handleCloseModal, successMessage } = useWebSocket();
    const [showWaiterCall, setShowWaiterCall] = useState(false);

    // Determine the logo based on the payment link content
    const isUpiLink = paymentLink && paymentLink.startsWith("upi://");
    const logoToUse = isUpiLink ? upiLogo : paymentLogo;  // Choose UPI logo if it's a UPI link, else use generic payment logo

    // Effect to handle auto-dismiss of waiter call notification
    useEffect(() => {
        if (waiterCall) {
            setShowWaiterCall(true);
            const timer = setTimeout(() => {
                setShowWaiterCall(false);
            }, 5000); // Dismiss after 5000 milliseconds (5 seconds)

            return () => clearTimeout(timer);
        }
    }, [waiterCall]);

    return (
        <React.Fragment>
            {showModal && paymentLink && (
                <Modal onClose={handleCloseModal}>
                    <div style={{ textAlign: 'center' }}>
                        <img src={logoToUse} alt="Payment Logo" style={{ width: '150px', marginBottom: '20px' }} />
                        <p>Please follow this link to complete your payment:</p>
                        <a
                            href={paymentLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                display: 'inline-block',
                                padding: '10px 20px',
                                margin: '10px 0',
                                backgroundColor: '#0070f3',
                                color: '#fff',
                                borderRadius: '5px',
                                textDecoration: 'none',
                            }}
                        >
                            Complete Payment
                        </a>
                    </div>
                </Modal>
            )}
            {successMessage && (
                <div
                    style={{
                        position: 'fixed',
                        bottom: '10px',
                        right: '10px',
                        backgroundColor: '#4caf50',
                        color: '#fff',
                        padding: '10px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    }}
                >
                    <strong>{successMessage}</strong>
                </div>
            )}
            {showWaiterCall && (
                <div
                    style={{
                        position: 'fixed',
                        bottom: '10px',
                        right: '10px',
                        backgroundColor: '#ffeb3b',
                        color: '#000',
                        padding: '10px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    }}
                >
                    <strong>Customer Call (Table {waiterCall.tableNumber}):</strong> {waiterCall.request}
                </div>
            )}
        </React.Fragment>
    );
}

export default NotificationDisplay;
