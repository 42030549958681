import React, { useState, useContext } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import './BusinessPaymentSetup.css';

function BusinessSetup() {
    const { token } = useContext(AuthContext);
    const [documents, setDocuments] = useState({
        identityProof: null,
        panCard: null,
        businessRegistrationProof: null,
    });
    const [uploadStatus, setUploadStatus] = useState({});
    const [businessDetails, setBusinessDetails] = useState({
        businessType: '',
        upiId: '',
        businessPAN: '',
        officialName: '',
        contactEmail: '',
        contactMobile: '',
        accountNumber: '',
        ifscCode: '',
        businessAddress: '',
        operationalAddress: '',
        gstin: '',
    });
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;
    const [modalVisible, setModalVisible] = useState(false);  // State for modal visibility

    const toggleModal = () => setModalVisible(!modalVisible);  // Function to toggle modal visibility

   // Additional state for privacy policy modal
    const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
    const togglePrivacyModal = () => setPrivacyModalVisible(!privacyModalVisible);

    const handleFileChange = (name, event) => {
        setDocuments(prev => ({ ...prev, [name]: event.target.files[0] }));
        setUploadStatus(prev => ({ ...prev, [name]: 'Ready to upload' }));
    };

    const handleInputChange = e => {
        setBusinessDetails(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

const validateInputs = () => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
    const mobileRegex = /^[6-9]\d{9}$/;
    const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/; // Basic validation for IFSC code

    if (!documents.identityProof || !documents.panCard || !documents.businessRegistrationProof) {
        setError('All documents are required. Please upload each document.');
        return false;
    }
    if (!businessDetails.upiId || !/^[a-zA-Z0-9.]+@[a-zA-Z0-9]+$/.test(businessDetails.upiId)) {
        setError('Invalid UPI ID format.');
        return false;
    }
    if (!businessDetails.businessPAN || !panRegex.test(businessDetails.businessPAN)) {
        setError('Invalid or missing Business PAN.');
        return false;
    }
    if (!businessDetails.contactEmail) {
        setError('Email is missing. Please enter an email address.');
        return false;
    }
    if (!businessDetails.contactMobile || !mobileRegex.test(businessDetails.contactMobile)) {
        setError('Invalid or missing mobile number.');
        return false;
    }
    if (!businessDetails.accountNumber.trim()) {
        setError('Bank account number is required.');
        return false;
    }
    if (!businessDetails.ifscCode.trim() || !ifscRegex.test(businessDetails.ifscCode)) {
        setError('Invalid or missing IFSC code.');
        return false;
    }
    setError('');
    return true;
};

    const uploadDocument = async (name, file) => {
        if (!file) {
            setError(`No file selected for ${name.replace(/([A-Z])/g, ' $1').trim()}.`);
            return;
        }
        const formData = new FormData();
        formData.append('document', file);
        setUploading(true);
        try {
            await axios.post(`${apiUrl}/api/business/upload-kyc`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            });
            setUploadStatus(prev => ({ ...prev, [name]: 'Uploaded successfully' }));
        } catch (error) {
            setUploadStatus(prev => ({ ...prev, [name]: 'Upload failed' }));
            setError(`Failed to upload ${name}: ${error.message}`);
        }
        setUploading(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateInputs()) return;

        const uploadPromises = Object.keys(documents).map(key => uploadDocument(key, documents[key]));
        await Promise.all(uploadPromises);
/*

        if (Object.values(uploadStatus).every(status => status === 'Uploaded successfully')) {
            if (await registerMerchant()) {
                setError('All documents and business details have been successfully submitted!');
                setDocuments({
                    identityProof: null,
                    panCard: null,
                    businessRegistrationProof: null,
                });
                setBusinessDetails({
                    businessType: '',
                    upiId: '',
                    businessPAN: '',
                    officialName: '',
                    contactEmail: '',
                    contactMobile: '',
                    accountNumber: '',
                    ifscCode: '',
                    businessAddress: '',
                    operationalAddress: '',
                    gstin: '',
                });
            }
        }
 */
    };

    const registerMerchant = async () => {
        setLoading(true);
        try {
            await axios.post(`${apiUrl}/api/business/register-merchant`, businessDetails, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            setError('Failedto register merchant: ' + error.message);
            return false;
        }
    };

    return (
        <div className="business-payment-container">
            <h2 className="business-payment-header">Business Payment Setup</h2>
             <p className="form-description">
                            Please provide the following information to set up your business payment account. This information is required to verify your identity and business details so you can start receiving payments directly into your bank account.
                        </p>
            {error && <p className="error-message">{error}</p>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label className="form-label">Identity Proof (Aadhaar, Passport, or Voter ID):</label>
                    <input type="file" onChange={(e) => handleFileChange('identityProof', e)} disabled={uploading} className="input-field" />
                    {uploadStatus.identityProof && <p className="upload-status">{uploadStatus.identityProof}</p>}
                </div>
                <div>
                    <label className="form-label">PAN Card:</label>
                    <input type="file" onChange={(e) => handleFileChange('panCard', e)} disabled={uploading} className="input-field" />
                    {uploadStatus.panCard && <p className="upload-status">{uploadStatus.panCard}</p>}
                </div>
                <div>
                    <label className="form-label">
                        Business Registration Proof:
                        <span className="info-icon" onClick={toggleModal}> ⓘ </span>  {/* Change to an info icon */}
                    </label>
                    <input type="file" onChange={(e) => handleFileChange('businessRegistrationProof', e)} disabled={uploading} />
                    {uploadStatus.businessRegistrationProof && <p>{uploadStatus.businessRegistrationProof}</p>}
                </div>
                <div>
                    <input
                        type="text"
                        name="upiId"
                        value={businessDetails.upiId}
                        onChange={handleInputChange}
                        placeholder="Enter your business UPI ID"
                        disabled={loading || uploading}
                        className="input-field"
                    />
                    <input
                        type="text"
                        name="businessPAN"
                        value={businessDetails.businessPAN}
                        onChange={handleInputChange}
                        placeholder="Enter your Business PAN"
                        disabled={loading || uploading}
                        className="input-field"
                    />
                    <input
                        type="email"
                        name="contactEmail"
                        value={businessDetails.contactEmail}
                        onChange={handleInputChange}
                        placeholder="Enter your email"
                        disabled={loading || uploading}
                        className="input-field"
                    />
                    <input
                        type="text"
                        name="businessType"
                        value={businessDetails.businessType}
                        onChange={handleInputChange}
                        placeholder="Enter your Business Type"
                        disabled={loading || uploading}
                        className="input-field"
                    />
                    <input
                        type="text"
                        name="contactMobile"
                        value={businessDetails.contactMobile}
                        onChange={handleInputChange}
                        placeholder="Enter your Contact Number"
                        disabled={loading || uploading}
                        className="input-field"
                    />
                    <input
                        type="text"
                        name="accountNumber"
                        value={businessDetails.accountNumber}
                        onChange={handleInputChange}
                        placeholder="Enter your Bank Account Number"
                        disabled={loading || uploading}
                        className="input-field"
                    />
                    <input
                        type="text"
                        name="ifscCode"
                        value={businessDetails.ifscCode}
                        onChange={handleInputChange}
                        placeholder="Enter your Bank IFSC Code"
                        disabled={loading || uploading}
                        className="input-field"
                    />
                    <input
                        type="text"
                        name="businessAddress"
                        value={businessDetails.businessAddress}
                        onChange={handleInputChange}
                        placeholder="Enter your Business Address"
                        disabled={loading || uploading}
                        className="input-field"
                    />
                    <input
                        type="text"
                        name="operationalAddress"
                        value={businessDetails.operationalAddress}
                        onChange={handleInputChange}
                        placeholder="Enter your Operational Address (if different)"
                        disabled={loading || uploading}
                        className="input-field"
                    />
                    <input
                        type="text"
                        name="gstin"
                        value={businessDetails.gstin}
                        onChange={handleInputChange}
                        placeholder="Enter your GSTIN (if applicable)"
                        disabled={loading || uploading}
                        className="input-field"
                    />
                </div>

             <div className="form-consent">
                                <input
                                    type="checkbox"
                                    id="consentCheckbox"
                                    name="consentCheckbox"
                                    required
                                />
                                <label htmlFor="consentCheckbox" className="checkbox-label">
                                    I agree to the Terms and Conditions.
                                    <span className="info-icon" onClick={togglePrivacyModal}> ⓘ </span>
                                </label>
                            </div>

                <button type="submit" disabled={uploading || loading} className="save-button">
                    {uploading || loading ? 'Processing...' : 'Submit All'}
                </button>
            </form>
             {modalVisible && (
                            <div className="modal">
                                <div className="modal-content">
                                    <span className="close" onClick={toggleModal}>&times;</span>
                                    <h4>Acceptable Business Registration Documents</h4>
                                    <ul>
                                        <li>Certificate of Incorporation</li>
                                        <li>Business License</li>
                                        <li>GST Registration Certificate</li>
                                        <li>Partnership Agreement</li>
                                        <li>LLP Agreement</li>
                                    </ul>
                                </div>
                            </div>
                        )}

                          {privacyModalVisible && (
                                        <div className="modal">
                                            <div className="modal-content">
                                                <span className="close" onClick={togglePrivacyModal}>&times;</span>
                                                <h4>Privacy Policy Details</h4>
                                                <p>
                                                    Our privacy policy outlines how we handle the collection, use, and protection of your information as required for compliance with financial regulations.
                                                </p>
                                                <ul>
                                                    <li><strong>Collection of Information:</strong> We collect essential details including your full name, business identifiers like GSTIN, contact information, financial documents such as PAN details, and bank account information for KYC purposes.</li>
                                                    <li><strong>Purpose:</strong> The information is used to verify your identity, set up and manage your business payment account, and ensure compliance with regulatory requirements.</li>
                                                    <li><strong>Security Measures:</strong> Your data is safeguarded with industry-standard security protocols to prevent unauthorized access.</li>
                                                    <li><strong>Sharing of Information:</strong> We may share your data with legal and financial entities as necessary for compliance, fraud prevention, and risk management.</li>
                                                    <li><strong>Your Rights:</strong> You are entitled to access the personal information we hold, request corrections, or ask for deletion, subject to regulatory retention requirements.</li>
                                                </ul>
                                                <p>
                                                    By checking the consent box, you agree to our use of your data in accordance with these terms.
                                                </p>
                                            </div>
                                        </div>
                                    )}
        </div>
    );
}

export default BusinessSetup;
