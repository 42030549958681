// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const parseUserFromLocalStorage = () => {
        const userData = localStorage.getItem('user');
        return userData ? JSON.parse(userData) : null;
    };

    const [authState, setAuthState] = useState({
        token: localStorage.getItem('token') || null,
        user: parseUserFromLocalStorage(),
    });

const login = (data, navigate) => {
    localStorage.setItem('token', data.jwtToken);
    localStorage.setItem('user', JSON.stringify(data.userDTO));
    setAuthState({
        token: data.jwtToken,
        user: data.userDTO,
    });
    const pendingOrder = localStorage.getItem('pendingOrder');
    if (pendingOrder) {
        const { businessProfileId, tableNumber,items } = JSON.parse(pendingOrder);
        navigate(`/menus?businessProfileId=${businessProfileId}&tableNumber=${tableNumber}`);
        localStorage.removeItem('pendingOrder'); // Clean up after handling
    } else {
        navigate('/'); // default navigation if no pending order
    }
};


    const logout = (navigate) => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setAuthState({ token: null, user: null });
    };

const checkTokenExpiration = (token) => {
    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Get current time in seconds

        if (decodedToken.exp < currentTime) {
            return true; // Token has expired
        }
    } catch (error) {
        console.error("Failed to decode token:", error);
        return true; // Assume token is invalid if an error occurs
    }

    return false; // Token is not expired
};
    useEffect(() => {
            // Check token validity and redirect if necessary on component mount
        if (!authState.token || checkTokenExpiration(authState.token)) {
                logout(); // If no token or token is invalid, logout (which redirects to login)
            }
        // React to changes in local storage across tabs
        const handleStorageChange = () => {
            setAuthState({
                token: localStorage.getItem('token') || null,
                user: parseUserFromLocalStorage(),
            });
        };
        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, []);

    return (
        <AuthContext.Provider value={{ ...authState, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
