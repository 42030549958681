import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import LoadingSpinner from './LoadingSpinner';

function QRCodeComponent() {
    const { token, user } = useContext(AuthContext);
    const [qrCodeUrls, setQRCodeUrls] = useState([]);
    const [businessProfileId, setBusinessProfileId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if (!user?.id || !token) {
            setError('User ID or token is missing');
            setLoading(false);
            return;
        }

        setLoading(true);
        axios.get(`${apiUrl}/api/business/${user.id}/business-profile`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(profileResponse => {
            if (profileResponse.data && profileResponse.data.id) {
                setBusinessProfileId(profileResponse.data.id);
                setError('');
                fetchQRCodes(profileResponse.data.id); // Fetch QR codes after getting business profile ID
            } else {
                setError('Business profile ID not found in response');
                setLoading(false);
            }
        }).catch(error => {
            console.error('Error fetching business profile:', error);
            setError('Failed to fetch business profile');
            setLoading(false);
        });
    }, [token, user?.id]);

    const fetchQRCodes = (profileId) => {
        axios.get(`${apiUrl}/api/qrcode/generate-all/${profileId}`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            if (response.data && response.data.length > 0) {
                setQRCodeUrls(response.data);
            } else {
                setError('No QR codes available.');
            }
            setLoading(false);
        }).catch(error => {
            console.error('Error fetching QR Codes:', error);
            setError('Failed to fetch QR codes');
            setLoading(false);
        });
    };

    const downloadQRCode = (qrCodeUrl) => {
        const link = document.createElement('a');
        link.href = qrCodeUrl;
        link.download = `QRCode-${qrCodeUrl.split('/').pop()}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

  if (loading) {
    return <LoadingSpinner />;
  }    if (error) return <div style={{ textAlign: 'center' }}>Error: {error}</div>;
    if (!loading && qrCodeUrls.length === 0) return <div style={{ textAlign: 'center' }}>No QR codes available.</div>;

    return (
        <div style={{ textAlign: 'center', margin: '20px' }}>
            {qrCodeUrls.map((url, index) => (
                <div key={index}>
                    <img src={url} alt={`QR Code Table ${index + 1}`} style={{ margin: '10px', width: '256px', height: '256px' }} />
                    <br />
                    <button onClick={() => downloadQRCode(url)} style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}>
                        Download QR Code Table {index + 1}
                    </button>
                </div>
            ))}
        </div>
    );
}

export default QRCodeComponent;
