// src/components/CustomerOrdersPage.js
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import styles from './CustomerOrdersPage.module.css';  // Ensure CSS module path is correct
import LoadingSpinner from './LoadingSpinner';

function CustomerOrdersPage() {
    const { token } = useContext(AuthContext);
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        fetchCustomerOrders();
    }, [token]);

    const fetchCustomerOrders = () => {
        setLoading(true);
        axios.get(`${apiUrl}/api/orders/my-orders`, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(response => {
            setOrders(response.data);
            setLoading(false);
        }).catch(error => {
            console.error('Error fetching customer orders:', error);
            setError('Failed to fetch orders. Please try again.');
            setLoading(false);
        });
    };

  if (loading) {
    return <LoadingSpinner />;
  }    if (error) return <div className={styles.error}>Error: {error}</div>;
    if (orders.length === 0) return <div className={styles.noOrders}>No orders found.</div>;

    return (
        <div className={styles.ordersContainer}>
            <h1 className={styles.ordersHeader}>Your Orders</h1>
            <div className={styles.tableWrapper}>
                <table className={styles.ordersTable}>
                    <thead>
                        <tr>
                            <th>Order ID</th>
                            <th>Date</th>
                            <th>Restaurant Name</th>
                            <th>Total Price</th>
                            <th>Status</th>
                            <th>Items</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map(order => (
                            <tr key={order.id}>
                                <td>{order.id}</td>
                                <td>{new Date(order.orderTime).toLocaleString()}</td>
                                <td>{order.businessName}</td>
                                <td>{order.totalPrice.toFixed(2)}</td>
                                <td>{order.status}</td>
                                <td>
                                    {order.items.map(item => (
                                        <div key={item.id} className={styles.productItem}>
                                            <span className={styles.productName}>{item.productName}</span>
                                            <span className={styles.quantityBadge}>Qty: {item.quantity}</span>
                                        </div>
                                    ))}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default CustomerOrdersPage;
