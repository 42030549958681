// c/context/NotificationContext.js
import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { useAuth } from './AuthContext'; // Adjust the import path as needed
import notificationSound from '../sound/notification_sound.wav'; // Ensure this path is correct

export const NotificationContext = createContext(null);

export const NotificationProvider = ({ children }) => {
  const { user } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const notificationAudio = new Audio(notificationSound);

  const playSound = useCallback(() => {
    // Check if sound is allowed to play
    notificationAudio.play().catch((error) => {
      // Handle the promise to avoid uncaught promise rejections
      console.warn('Audio playback failed:', error);
    });
  }, [notificationAudio]);

  const addNotification = useCallback((notification) => {
    setNotifications((prevNotifications) => [...prevNotifications, notification]);
    playSound();
  }, [playSound]);

  useEffect(() => {
    if (user) {
      //connect(user.id, addNotification);
    }
    return () => {
      if (user) {
        //disconnect();
      }
    };
  }, [user,  addNotification]);


// Inside NotificationProvider
const clearChatNotifications = useCallback((appointmentId) => {
    setNotifications((prevNotifications) => prevNotifications.map(notification => {
        if (notification.appointmentId === appointmentId) {
            // Mark the notification as read or remove it
            return { ...notification, read: true };
        }
        return notification;
    }));
}, []);
  return (
    <NotificationContext.Provider value={{ notifications, setNotifications, addNotification, clearChatNotifications }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationContext);
