    import React, { useState, useEffect, useContext,useRef } from 'react';
    import axios from 'axios';
    import { useLocation, useNavigate } from 'react-router-dom';
    import SockJS from 'sockjs-client';
    import { Client } from '@stomp/stompjs';
    import styles from './MenuViewer.module.css';
    import AuthContext from '../context/AuthContext';
    import Modal from './Modal'; // Assuming you have a Modal component
    import { Stomp } from '@stomp/stompjs';  // Correct import for Stomp
    import bellSound from '../assets/copper-bell-ding-4-204990.mp3';

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    function MenuViewer() {
        const navigate = useNavigate();
        const { token, user } = useContext(AuthContext);
        const [menuItems, setMenuItems] = useState([]);
        const [selectedItems, setSelectedItems] = useState(JSON.parse(localStorage.getItem('selectedItems')) || []);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState('');
        //const [paymentLink, setPaymentLink] = useState(null);
        const [showModal, setShowModal] = useState(false);
        const apiUrl = process.env.REACT_APP_API_URL;  // Correct usage of environment variable
        const [stompClient, setStompClient] = useState(null);  // Initialize state for Stomp client
        const audioRef = useRef(new Audio(bellSound)); // Use useRef to persist the Audio object

        const query = useQuery();
        const businessProfileId = query.get('businessProfileId');
        const tableNumber = query.get('tableNumber');

        useEffect(() => {
            if (businessProfileId && tableNumber) {
                loadMenuItems(businessProfileId, tableNumber);
                //connectToPaymentGateway();
                            localStorage.setItem('tableNumber', tableNumber); // Store table number for later use
                            localStorage.setItem('businessProfileId', businessProfileId); // Store table number for later use
            } else {
                setError("Missing essential URL parameters.");
            }
        }, [businessProfileId, tableNumber]);

    /* const connectToPaymentGateway = () => {
        const socket = new SockJS('http://localhost:8081/ws');
        const stompClient = new Client({
            webSocketFactory: () => socket,
            reconnectDelay: 5000,
            debug: (str) => console.log('STOMP Debug:', str),
              connectHeaders: {
                        Authorization: `Bearer ${token}`, // Include the token in the STOMP connection headers
                    },
        });

        // Activate the STOMP client right away
        stompClient.activate();

     // Correct way to subscribe to user-specific messages
     stompClient.onConnect = (frame) => {
         console.log('Connected:', frame);

         // Subscribe to the user-specific queue for payments
         stompClient.subscribe(`/queue/payments/${user.id}`, (message) => {
             const paymentLink = message.body;
             console.log("Payment Link Received:", paymentLink);
             setPaymentLink(paymentLink);
             setShowModal(true);
             audioRef.current.play().catch(e => console.error('Failed to play:', e));

         });
     };


        stompClient.onStompError = (frame) => {
            console.error('STOMP Error:', frame.headers.message);
            setError('Connection error: ' + frame.headers.message);
        };

        // This could be expanded to handle disconnects, etc.
    };
 */
        const loadMenuItems = async (businessProfileId, tableNumber) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/menus`, {
                    params: { businessProfileId, tableNumber },
                    headers: { Authorization: `Bearer ${token}` }
                });
                setMenuItems(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching menu items:', error);
                setError('Failed to load menu items');
                setLoading(false);
            }
        };

        const handleAddToOrder = (item) => {
            setSelectedItems(prevItems => [...prevItems, item]);
        };

        const handleRemoveFromOrder = (id) => {
            setSelectedItems(prevItems => prevItems.filter(item => item.id !== id));
        };

        const handlePlaceOrder = () => {
            if (!user) {
                localStorage.setItem('pendingOrder', JSON.stringify({ businessProfileId, items: selectedItems, tableNumber }));
                navigate('/otp-verify');
                return;
            }

            const orderDetails = {
                userId: user.id,
                businessProfileId,
                tableNumber,
                items: selectedItems.map(item => ({
                    productId: item.id,
                    quantity: 1,
                    price: item.price
                }))
            };

            axios.post(`${process.env.REACT_APP_API_URL}/api/orders`, orderDetails, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(response => {
                console.log('Order placed:', response.data);
                setSelectedItems([]);
                localStorage.removeItem('selectedItems');
                //if (paymentLink) {
                  //  setShowModal(true);
                //}
            }).catch(error => {
                console.error('Error placing order:', error.response);
                setError('Failed to place order');
            });
        };

        if (loading) return <div>Loading...</div>;
        if (error) return <div>Error: {error}</div>;

        return (
            <div className={styles.menuContainer}>
                <h1 className={styles.menuHeader}>Menu</h1>
                 <ul className={styles.menuList}>
                           {Array.isArray(menuItems) && menuItems.map(item => (
                               <li key={item.id} className={styles.menuItem}>
                                   <span className={styles.menuName}>{item.name}</span>
                                   <span className={styles.menuPrice}>{item.price.toFixed(2)}</span>
                                   <button onClick={() => handleAddToOrder(item)} className={styles.addButton}>Add</button>
                               </li>
                           ))}
                       </ul>
                {selectedItems.length > 0 && (
                    <div className={styles.orderSection}>
                        <h2 className={styles.orderTitle}>Your Order</h2>
                        <ul className={styles.orderList}>
                            {selectedItems.map(item => (
                                <li key={item.id} className={styles.orderItem}>
                                    <span className={styles.menuName}>{item.name}</span>
                                    <span className={styles.menuPrice}>{item.price.toFixed(2)}</span>
                                    <button onClick={() => handleRemoveFromOrder(item.id)} className={styles.removeButton}>Remove</button>
                                </li>
                            ))}
                        </ul>
                        <button onClick={handlePlaceOrder} disabled={!selectedItems.length}>
                            Place Order
                        </button>
                    </div>
                )}
                <div className={styles.menuContainer}>
                            {/* existing content */}
                         {/*    {showModal && (
                                <Modal onClose={() => setShowModal(false)}>
                                    <div>
                                        <p>Please follow this link to complete your payment:</p>
                                        <a href={paymentLink} target="_blank" rel="noopener noreferrer" className={styles.paymentLink}>
                                            {paymentLink}
                                        </a>
                                    </div>
                                </Modal>
                            )} */}
                        </div>
            </div>
        );
    }

    export default MenuViewer;
