// src/context/WebSocketContext.js
import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';
import AuthContext from './AuthContext';
import bellSound from '../assets/copper-bell-ding-4-204990.mp3';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    const { token, user } = useContext(AuthContext);
    const [paymentLink, setPaymentLink] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [waiterCall, setWaiterCall] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const audioRef = useRef(new Audio(bellSound));
    const [groupOrder, setGroupOrder] = useState(null);
    const [sessionId, setSessionId] = useState("");  // This should be set based on the current session
    const apiUrl = process.env.REACT_APP_API_URL;
    const [paymentSuccessMessage, setPaymentSuccessMessage] = useState(null); // Payment Success Message state
    const [newOrder, setNewOrder] = useState(null);

    useEffect(() => {
        if (!token || !user) return;

        const socket = new SockJS(`${apiUrl}/ws`);
        const stompClient = new Client({
            webSocketFactory: () => socket,
            connectHeaders: { Authorization: `Bearer ${token}` },
            reconnectDelay: 5000,
        });

        stompClient.onConnect = () => {
            if (user && user.id) {
                stompClient.subscribe(`/queue/payments/${user.id}`, (message) => {
                    const messageBody = message.body;
                    if (messageBody.startsWith("Payment successful")) {
                        setPaymentLink(null); // Clear payment link after success
                        setShowModal(false);
                        setSuccessMessage(messageBody);
                        setTimeout(() => setSuccessMessage(null), 5000); // Hide after 5 seconds
                    } else {
                        setPaymentLink(messageBody);
                        setShowModal(true);
                    }
                    audioRef.current.play().catch(e => console.error('Failed to play:', e));
                });
  stompClient.subscribe(`/queue/paymentSuccess/${user.id}`, (message) => {
                    setPaymentSuccessMessage(message.body); // Set payment success message
                });

  // Listen for new orders for the business profile
            if (user.businessProfileId) {
                stompClient.subscribe(`/topic/new-orders/${user.businessProfileId}`, (message) => {
                    setNewOrder(JSON.parse(message.body));
                    audioRef.current.play().catch(e => console.error('Failed to play:', e));
                });
            }

                  stompClient.subscribe(`/queue/service/${user.businessProfileId}`, (message) => {
                   const data = JSON.parse(message.body);
                   setWaiterCall(data);
                   console.log('Received waiter call:', data);
                   audioRef.current.play().catch(e => console.error('Failed to play:', e));
                  });

                if (sessionId) {
                    stompClient.subscribe(`/topic/group-order/${sessionId}`, (message) => {
                        setGroupOrder(JSON.parse(message.body));
                    });
                }
            }
        };

        stompClient.activate();
        return () => stompClient.deactivate();
    }, [token, user, sessionId, apiUrl]);

    const handleCloseModal = () => setShowModal(false);

    return (
        <WebSocketContext.Provider value={{ paymentLink, waiterCall, showModal, handleCloseModal, groupOrder, setSessionId, successMessage, paymentSuccessMessage, newOrder }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => useContext(WebSocketContext);
