import React, { useState, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

function Navigation() {
    const { user, logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = () => {
        logout();
        navigate('/');
        setIsOpen(false); // Close the menu upon logout
    };

    // Extracting 'authorities' directly from the user object now stored in context
    const isBusinessOwner = user?.authorities?.includes('ROLE_BUSINESS_OWNER');

    return (
        <nav className="navbar">
            <div className="hamburger-menu" onClick={toggleMenu}>
                &#9776; {/* Hamburger Icon */}
            </div>
            <div className={`nav-menu ${isOpen ? 'active' : ''}`}>
                <NavLink to="/" className="nav-link" onClick={toggleMenu}>Home</NavLink>
                {user && isBusinessOwner && (
                    <>
                        <NavLink to="/MenuImageUpload" className="nav-link" onClick={toggleMenu}>Upload Menu</NavLink>
                        <NavLink to="/QRCodeComponent" className="nav-link" onClick={toggleMenu}>Generate QR Code</NavLink>
                        <NavLink to="/OrdersPage" className="nav-link" onClick={toggleMenu}>Orders</NavLink>
                        <NavLink to="/BusinessPaymentSetup" className="nav-link" onClick={toggleMenu}>Payments Setup</NavLink>
                        <NavLink to="/MenuManagement" className="nav-link" onClick={toggleMenu}>Menu Management</NavLink>
                        <NavLink to="/UpiPaymentSetup" className="nav-link" onClick={toggleMenu}>Upi Payment</NavLink>

                    </>
                )}
                {user && !isBusinessOwner && (
                    <>
{/*
                        <NavLink to="/qr-scan" className="nav-link" onClick={toggleMenu}>Scan QR Code</NavLink>
 */}
                        <NavLink to="/CustomerOrdersPage" className="nav-link" onClick={toggleMenu}>My Orders</NavLink>
{/*
                        <NavLink to="/CustomerPaymentSetup" className="nav-link" onClick={toggleMenu}>UPI Setup</NavLink>
 */}
                        <NavLink to="/WaiterService" className="nav-link" onClick={toggleMenu}>Call Waiter</NavLink>


                    </>
                )}
                {user ? (
                    <button onClick={handleLogout} className="logout-btn">Logout</button>
                ) : (
                    <>
                        <NavLink to="/login" className="nav-link" onClick={toggleMenu}>Login</NavLink>
{/*
                        <NavLink to="/registration" className="nav-link" onClick={toggleMenu}>Register</NavLink>
 */}
{/*
                        <NavLink to="/otp-verify" className="nav-link" onClick={toggleMenu}>OTP Login</NavLink>
 */}
                        <NavLink to="/BusinessRegistrationForm" className="nav-link" onClick={toggleMenu}>Register Business</NavLink>
                    </>
                )}
            </div>
        </nav>
    );
}

export default Navigation;
