import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import './MenuImageUpload.css';
import LoadingSpinner from './LoadingSpinner';

function MenuImageUpload() {
    const { token } = useContext(AuthContext);
    const [file, setFile] = useState(null);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const baseURL = process.env.REACT_APP_API_URL;
    const [uploading, setUploading] = useState(false);

    const onFileChange = event => {
        setFile(event.target.files[0]);
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        if (!file) {
            setError('Please select a file before submitting.');
            return;
        }
        setLoading(true);
        setUploading(true);
        setError('');
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(`${baseURL}/api/images/upload`, formData, {
                headers: { Authorization: `Bearer ${token}` }
            });
                setUploading(false);
            if (Array.isArray(response.data)) {
                setProducts(response.data.map(item => ({ ...item, editing: false })));
            } else {
                setError('Invalid response format from the server.');
                setProducts([]);
            }
        } catch (error) {
            setUploading(false);
            setError(error.response ? error.response.data.message : 'Error uploading file. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleAddNewProduct = () => {
        // Use temporary IDs for new products that are not yet saved
        const tempId = `temp-${Date.now()}`;
        const newProduct = { id: tempId, name: '', price: 0.00, editing: true, isNew: true };
        setProducts([newProduct, ...products]);
    };

    const handleEdit = (id, key, value) => {
        setProducts(products.map(product => {
            if (product.id === id) {
                return { ...product, [key]: value };
            }
            return product;
        }));
    };

const handleCancel = (id) => {
    // Find the product with the given id
    const product = products.find(product => product.id === id);

    // If the product is new or hasn't had its name set, remove it from the list
    if (product && (product.isNew || product.name === '')) {
        setProducts(products.filter(product => product.id !== id));
    } else {
        // Otherwise, toggle its editing state
        toggleEditing(id);
    }
};


    const handleSaveChanges = async (product) => {
        setLoading(true);
        const { id, isNew, ...productData } = product;
        try {
            let response;
            if (isNew) {
                // If the product is new, send a POST request to create it
                response = await axios.post(`${baseURL}/api/products/createProduct`, productData, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                productData.id = response.data.id; // Update with actual ID from response
            } else {
                // If the product is not new, send a PUT request to update it
                response = await axios.put(`${baseURL}/api/products/updateProduct/${id}`, productData, {
                    headers: { Authorization: `Bearer ${token}` }
                });
            }
            setProducts(products.map(p => (p.id === id ? { ...productData, editing: false, isNew: false } : p)));
        } catch (error) {
            setError('Failed to save changes.');
        } finally {
            setLoading(false);
        }
    };

    const toggleEditing = (id) => {
        setProducts(products.map(product => {
            if (product.id === id) return { ...product, editing: !product.editing };
            return product;
        }));
    };

    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${baseURL}/api/products/getProductsById`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setProducts(response.data.map(product => ({ ...product, editing: false })));
            } catch (error) {
                setError('Failed to fetch products.');
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, [token, baseURL]);

  if (loading) {
    return <LoadingSpinner />;
  }
    return (
            <div className="MenuImageUpload"> {/* Add scoped class here */}

        <div>
            <div className="formSection">
              <h2>Welcome to the Digital Menu Setup</h2>
              <p>Please upload your menu image. Our system will convert it into a digital menu, allowing you to edit and manage it online.</p>
                <form onSubmit={onSubmit}>
                    <input type="file" onChange={onFileChange} className="fileInput" />
                    <button type="submit" className="uploadButton" disabled={!file || loading}>Upload</button>
                    <button type="button" onClick={handleAddNewProduct} className="uploadButton">Add</button>
                </form>
                                {uploading && <p>Uploading your menu image, please wait...</p>}
            </div>
            <div className="productSection">
                <h2>Menu</h2>
                {error && <p className="error">{error}</p>}
                <ul className="productList">
                    {products.map(product => (
                        <li key={product.id} className="productItem">
                            {product.editing ? (
                                <>
                                    <input
                                        type="text"
                                        value={product.name}
                                        onChange={(e) => handleEdit(product.id, 'name', e.target.value)}
                                        className="editInput"
                                    />
                                    <input
                                        type="number"
                                        value={product.price}
                                        onChange={(e) => handleEdit(product.id, 'price', parseFloat(e.target.value))}
                                        className="editInput"
                                    />
                                    <button onClick={() => handleSaveChanges(product)} className="button saveButton">Save</button>
                                    <button onClick={() => handleCancel(product.id)} className="button cancelButton">Cancel</button>
                                </>
                            ) : (
                                <>
                                    <span className="productName">{product.name}</span>
                                  <span className="productPrice">{(product.price ?? 0).toFixed(2)}</span>
                                    <button onClick={() => toggleEditing(product.id)} className="button editButton">Edit</button>
                                </>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
                </div>

    );
}

export default MenuImageUpload;
