import React, { useState } from 'react';
import { Box, Container, Typography, Button, CircularProgress, Modal, useMediaQuery } from '@mui/material';
import QrCodeIcon from '@mui/icons-material/QrCode';
import QrReader from 'react-qr-reader-es6';
import backgroundImage from '../assets/image.webp';
import styles from './Home.module.css';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width:600px)');
  const [isScanning, setIsScanning] = useState(false);
  const [scanResult, setScanResult] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState('');

  const handleScan = data => {
    if (data) {
      console.log("QR Code Scanned:", data);
      if (data.startsWith('http')) {
        window.location.href = data;  // Navigate to external links directly
      } else {
        navigate(data);  // Navigate internally within the app
      }
      setScanResult(data);
      handleClose();
    }
  };

  const handleError = err => {
    setError(`Scanning Error: ${err}`);
    setIsScanning(false);
  };

  const handleOpen = () => {
    setOpenModal(true);
    setIsScanning(true);
    setError('');
    setScanResult('');
  };

  const handleClose = () => {
    setOpenModal(false);
    setIsScanning(false);
  };

  return (
    <Box className={styles.homeContainer} style={{ backgroundImage: `url(${backgroundImage})` }}>
      <Container maxWidth="sm" className={styles.container}>
          <Box className="typography-container">

        <Typography variant={matches ? 'h4' : 'h3'} gutterBottom>
          Q-der!
        </Typography>
        <Typography variant="subtitle1">
          Discover and order from your table with ease! Scan the QR code on your table to view the menu, make selections, and place your order directly from your phone. Enjoy a seamless dining experience at your favorite restaurants.
        </Typography>
            </Box>

        <Button
          className={styles.button}
          variant="contained"
          color="primary"
          size={matches ? 'medium' : 'large'}
          startIcon={<QrCodeIcon />}
          onClick={handleOpen}
          disabled={isScanning}
        >
          {isScanning ? <CircularProgress size={24} /> : 'Scan to Start'}
        </Button>
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Box className={styles.modalContainer}>
            {/*   <Typography id="modal-modal-title" variant="h6">
                  Scan QR Code
              </Typography> */}
              <QrReader
                  delay={300}
                  onError={handleError}
                  onScan={handleScan}
                  style={{ width: '100%' }} // This ensures the QR reader takes up the full width of the modal
              />
              {error && (
                  <Typography color="error">{error}</Typography>
              )}
              {scanResult && (
                  <Typography>Result: {scanResult}</Typography>
              )}
              <Button
                  onClick={handleClose} // Closes the modal
                  sx={{ mt: 2 }} // Adds margin top for spacing
                  variant="outlined"
                  color="secondary"
              >
                  Close Scanner
              </Button>
          </Box>

        </Modal>
</Container>
    </Box>
  );
};

export default Home;
