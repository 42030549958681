    import React, { useState, useContext, useEffect } from 'react';
    import axios from 'axios';
    import AuthContext from '../context/AuthContext';
    import './CustomerPaymentSetup.css'; // This line imports the CSS styles
    import LoadingSpinner from './LoadingSpinner';

    function CustomerPaymentSetup() {
        const { token } = useContext(AuthContext);
        const [paymentDetails, setPaymentDetails] = useState('');
        const [savedPaymentDetails, setSavedPaymentDetails] = useState('');
        const [loading, setLoading] = useState(false);
        const [message, setMessage] = useState('');
        const apiUrl = process.env.REACT_APP_API_URL;

        useEffect(() => {
            const fetchPaymentDetails = async () => {
                setLoading(true);
                try {
                    const response = await axios.get(`${apiUrl}/api/customers/payment-method`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    if (response.data && response.data.paymentDetails) { // Adjusted to check for paymentDetails
                        setSavedPaymentDetails(response.data.paymentDetails); // Adjusted to set paymentDetails
                    }
                } catch (error) {
                    setMessage('Failed to fetch payment details: ' + (error.response?.data.message || 'Unable to fetch data'));
                } finally {
                    setLoading(false);
                }
            };

            fetchPaymentDetails();
        }, [token, apiUrl]);

        const validateUpiId = (upiId) => /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+$/.test(upiId);

        const handleSubmit = async (e) => {
            e.preventDefault();
            setMessage('');
            if (!validateUpiId(paymentDetails)) {
                setMessage('Invalid UPI ID format.');
                return;
            }

            setLoading(true);
            try {
                const response = await axios.post(`${apiUrl}/api/customers/payment-method`, {
                    upiId: paymentDetails
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setSavedPaymentDetails(paymentDetails);
                setMessage('Payment method saved successfully!');
            } catch (error) {
                setMessage('Failed to save payment details: ' + (error.response?.data.message || error.message));
            } finally {
                setLoading(false);
            }
        };

        return (
            <div className="customer-payment-container">
                       <h2>Setup Customer Payment Method</h2>

                       {!savedPaymentDetails ? (
                           <form onSubmit={handleSubmit} className="payment-form">
                               <label className="form-label">
                                   Payment Details:
                                   <input
                                       type="text"
                                       value={paymentDetails}
                                       onChange={e => setPaymentDetails(e.target.value)}
                                       placeholder="Enter your UPI ID"
                                       disabled={loading}
                                       className="input-field"
                                   />
                               </label>
                               <button type="submit" disabled={loading} className="button">
                                   {loading ? 'Saving...' : 'Save Payment Details'}
                               </button>
                           </form>
                       ) : (
                           <div>
                               <p>Payment Details: {savedPaymentDetails}</p>
                               <button onClick={() => setSavedPaymentDetails('')} className="button">Edit</button>
                           </div>
                       )}
                       {message && <p className="message">{message}</p>}
                   </div>
        );
    }
    
    export default CustomerPaymentSetup;
