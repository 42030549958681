import React, { useState } from 'react';
import axios from 'axios';
import './BusinessRegistrationForm.css';  // Ensure CSS is correctly linked

function BusinessRegistrationForm() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    ownerName: '',
    businessName: '',
    businessAddress: '',
    businessType: '',
    phoneNumber: ''  // Added field for phone number
  });
  const [message, setMessage] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

const validatePhoneNumber = (number) => {
  // This regex allows for optional +91 or 0 at the start, followed by ten digits which may have spaces or dashes between them
  const pattern = /^(?:\+91[-\s]?)?0?[-\s]?\d{5}[-\s]?\d{5}$/;
  return pattern.test(number);
};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
        if (!validatePhoneNumber(formData.phoneNumber)) {
          setMessage("Invalid phone number. Please enter a 10-digit phone number.");
          return;
        }
    try {
      const response = await axios.post(`${apiUrl}/api/business/register`, formData);
      setMessage('Business registered successfully!');
    } catch (error) {
          const errorMessage = error.response && error.response.data ? error.response.data : 'Failed to register business. Please try again.';
      setMessage(errorMessage);
    }
  };

  return (
    <div className="business-registration-container">
      <h2 className="business-registration-header">Register Your Business</h2>
      <form onSubmit={handleSubmit} className="business-registration-form">
        <label>
          Email:
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
        </label>
        <label>
          Password:
          <input type="password" name="password" value={formData.password} onChange={handleChange} required />
        </label>
        <label>
          Owner Name:
          <input type="text" name="ownerName" value={formData.ownerName} onChange={handleChange} required />
        </label>
        <label>
          Business Name:
          <input type="text" name="businessName" value={formData.businessName} onChange={handleChange} required />
        </label>
        <label>
          Business Address:
          <input type="text" name="businessAddress" value={formData.businessAddress} onChange={handleChange} required />
        </label>
        <label>
                  Phone Number:
          <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
         </label>
        <label>
          Business Type:
          <select name="businessType" value={formData.businessType} onChange={handleChange} required>
            <option value="">Select Type</option>
            <option value="RESTAURANT">Restaurant</option>
            <option value="CAFE">Cafe</option>
            <option value="BOOKSTORE">Bookstore</option>
            <option value="CLOTHING_STORE">Clothing Store</option>
            <option value="OTHER">Other</option>
          </select>
        </label>
        <button type="submit">Register Business</button>
      </form>
      <p className="business-registration-message">{message}</p>
    </div>
  );
}

export default BusinessRegistrationForm;
