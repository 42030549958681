// src/components/LoadingSpinner.js
import React from 'react';
import { ClipLoader } from 'react-spinners';

const LoadingSpinner = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <ClipLoader color="#123abc" size={150} />
    </div>
  );
};

export default LoadingSpinner;
