import React, { useState, useRef, useEffect } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import { useNavigate } from 'react-router-dom';
import './QrCodeScanner.css';
import defaultQRPic from '../assets/qr.webp';

const QrCodeScanner = () => {
  const [isScanning, setIsScanning] = useState(false);
  const qrRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    let scanner;
    if (isScanning) {
      scanner = new Html5Qrcode("qr-reader");
      const config = { fps: 10, qrbox: 250 };
      scanner.start(
        { facingMode: "environment" },
        config,
        (decodedText, decodedResult) => {
          console.log("QR Code Scanned:", decodedText, decodedResult);
          // Handling URL navigation or actions based on QR code content
          if (decodedText.startsWith('http')) {
            window.location.href = decodedText;
          } else {
            navigate(decodedText, { replace: true });
          }
          scanner.stop().then(() => setIsScanning(false));
        },
        errorMessage => console.error("QR scanning error:", errorMessage)
      ).catch(err => console.error("Unable to start QR scanner", err));
    }

    return () => {
      if (scanner) {
        scanner.stop().catch(err => console.error("Problem stopping QR Scanner", err));
      }
    };
  }, [isScanning, navigate]);

  return (
    <div className="qr-scanner-container">
      <div id="qr-reader" className={`qr-reader ${isScanning ? '' : 'scanner-inactive'}`}>
        {!isScanning && (
          <img src={defaultQRPic} alt="Scan Icon" className="scan-icon"/>
        )}
      </div>
      <button onClick={() => setIsScanning(!isScanning)} className="scan-button">
        {isScanning ? 'Stop Scanning' : 'Start Scanning'}
      </button>
      <p className="scan-info">
        {isScanning ? 'Scanning... Please point your camera at a QR code.' : 'Scan now to view the menu.'}
      </p>
    </div>
  );
};

export default QrCodeScanner;
