import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import './Login.css'; // Assuming your CSS file is named Login.css

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    useEffect(() => {
        window.google?.accounts.id.initialize({
            client_id: clientId,
            callback: handleCredentialResponse
        });
        window.google?.accounts.id.renderButton(
            document.getElementById('signInDiv'),
            { theme: 'outline', size: 'large' }
        );
        window.google?.accounts.id.prompt(); // This might auto-prompt users
    }, [clientId]);

    const handleCredentialResponse = (response) => {
        setLoading(true);
        axios.post(`${apiUrl}/auth/verifyGoogleToken`, { token: response.credential })
            .then(res => {
                if (res.data && res.data.jwtToken) {
                    login(res.data, navigate);
                } else {
                    throw new Error('Invalid response from server');
                }
            })
            .catch(error => {
                console.error('Verification error:', error);
                setError(`Failed to verify Google login: ${error.message || 'Unknown error'}`);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!email || !password) {
            setError('Email and Password are required.');
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post(`${apiUrl}/auth/login`, { email, password });
            login(response.data, navigate);
        } catch (err) {
            console.error('Error during authentication:', err);
            setError('Authentication failed. Please check your credentials and try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
      <div className="login-container">
          <h2>Login</h2>
          {error && <div className="error-message">{error}</div>}
          <form onSubmit={handleSubmit} className="login-form">
              <label>Email:<input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required /></label>
              <label>Password:<input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required /></label>
              <button type="submit" disabled={loading}>{loading ? 'Logging in...' : 'Login'}</button>
          </form>
          <div id="signInDiv"></div> {/* Google Sign-in button will be rendered here */}
      </div>

    );
};

export default Login;
