import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import styles from './MenuManagement.module.css';
import LoadingSpinner from './LoadingSpinner';

function MenuManagement() {
    const { token } = useContext(AuthContext);
    const [menuItems, setMenuItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchMenuItems();
    }, [token]);

    const fetchMenuItems = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/products/getProductsById`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setMenuItems(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching menu items:', error);
            setError('Failed to load menu items');
            setLoading(false);
        }
    };

    const updateCategory = async (productId, category) => {
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/products/${productId}/category`, null, {
                params: { category },
                headers: { Authorization: `Bearer ${token}` }
            });
            fetchMenuItems();
        } catch (error) {
            console.error('Error updating category:', error);
            alert('Failed to update category');
        }
    };

    const updateAvailability = async (productId, isAvailable) => {
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/products/${productId}/availability`, null, {
                params: { isAvailable },
                headers: { Authorization: `Bearer ${token}` }
            });
            fetchMenuItems();
        } catch (error) {
            console.error('Error updating availability:', error);
            alert('Failed to update availability');
        }
    };

  if (loading) {
    return <LoadingSpinner />;
  }    if (error) return <div>Error: {error}</div>;

    return (
        <div className={styles.menuManagementContainer}>
            <h1 className={styles.menuHeader}>Manage Menu</h1>
            <table className={styles.menuTable}>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Price</th>
                        <th>Category</th>
                        <th>Availability</th>
                    </tr>
                </thead>
                <tbody>
                    {menuItems.map(item => (
                        <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{item.price.toFixed(2)}</td>
                            <td>
                                <select
                                    value={item.category || ''}
                                    onChange={(e) => updateCategory(item.id, e.target.value)}
                                    className={styles.categorySelect}
                                >
                                    <option value="">Select</option>
                                    <option value="Breakfast">Breakfast</option>
                                    <option value="Lunch">Lunch</option>
                                    <option value="Dinner">Dinner</option>
                                    <option value="Other">Other</option>
                                </select>
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={item.available}
                                    onChange={() => updateAvailability(item.id, !item.available)}
                                    className={styles.availabilityCheckbox}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default MenuManagement;
