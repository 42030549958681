import React, { useState, useContext, useEffect } from 'react';
import { Client } from '@stomp/stompjs';
import AuthContext from '../context/AuthContext';
import SockJS from 'sockjs-client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import "./WaiterService.css";

function WaiterService() {
    const { token } = useContext(AuthContext);
    const [stompClient, setStompClient] = useState(null);
    const [connected, setConnected] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setupWebSocketConnection();
        return teardownWebSocketConnection;
    }, [token, apiUrl]);

    const setupWebSocketConnection = () => {
        const socket = new SockJS(`${apiUrl}/ws`);
        const client = new Client({
            webSocketFactory: () => socket,
            connectHeaders: { Authorization: `Bearer ${token}` },
            onConnect: () => handleWebSocketConnect(client),
            onStompError: handleWebSocketError,
            reconnectDelay: 5000,
        });
        client.activate();
    };

    const handleWebSocketConnect = (client) => {
        console.log('Connected to WebSocket');
        setStompClient(client);
        setConnected(true);
    };

    const handleWebSocketError = (error) => {
        console.error('STOMP Error:', error);
        setConnected(false);
    };

    const teardownWebSocketConnection = () => {
        setConnected(false);
        if (stompClient) {
            stompClient.deactivate();
        }
    };

    const sendServiceRequest = (requestType) => {
        const businessProfileId = localStorage.getItem('businessProfileId');
        const tableNumber = localStorage.getItem('tableNumber');
        if (stompClient && connected && businessProfileId && tableNumber) {
            stompClient.publish({
                destination: '/app/waiterCall',
                body: JSON.stringify({ request: requestType, tableNumber, businessProfileId }),
            });
            console.log(`Service request sent: ${requestType} for table ${tableNumber}`);
        } else {
            console.log('STOMP client is not connected or required data is missing.');
        }
    };

    return (
        <div className="waiter-service-container">
            <button onClick={() => sendServiceRequest('I need water')} disabled={!connected} className="service-button">Request Water</button>
            <button onClick={() => sendServiceRequest('Get me bill')} disabled={!connected} className="service-button">Request Bill</button>
            <button onClick={() => sendServiceRequest('Waiter')} disabled={!connected} className="service-button">Call Waiter</button>
            <div className="connection-status">
                {!connected ? (
                    <>
                        <FontAwesomeIcon icon={faSpinner} spin className="icon-spinner" />
                        Connecting...
                    </>
                ) : (
                    <>
                        <FontAwesomeIcon icon={faCheckCircle} className="icon-connected" />
                        Connected
                    </>
                )}
                {!connected && <FontAwesomeIcon icon={faTimesCircle} className="icon-error" />}
            </div>
        </div>
    );
}

export default WaiterService;
