// src/components/OrdersPage.js
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import styles from './OrdersPage.module.css';
import SockJS from 'sockjs-client';
import { Client as StompClient } from '@stomp/stompjs';
import { useWebSocket } from '../context/WebSocketContext'; // Correct import
import LoadingSpinner from './LoadingSpinner';

function OrdersPage() {
    const { token } = useContext(AuthContext);
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [stompClient, setStompClient] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    const { paymentSuccessMessage, newOrder } = useWebSocket(); // Use the hook instead of WebSocketContext

    useEffect(() => {
        fetchOrders();
    }, [token]);
  // Fetch orders whenever payment success message changes
    useEffect(() => {
        if (paymentSuccessMessage) fetchOrders();
    }, [paymentSuccessMessage]);

  // Add new order to the orders list
    useEffect(() => {
        if (newOrder) {
             fetchOrders();//setOrders(prevOrders => [...prevOrders, newOrder]);
        }
    }, [newOrder]);


    const fetchOrders = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/api/orders`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            const sortedOrders = response.data.sort((a, b) => new Date(b.orderTime) - new Date(a.orderTime));
            setOrders(sortedOrders);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching orders:', error);
            setError('Failed to fetch orders. Please try again.');
            setLoading(false);
        }
    };


    const initiatePayment = async (orderId) => {
        const order = orders.find(o => o.id === orderId);
        if (!order) {
            alert("Order not found!");
            return;
        }
if (order.paymentStatus === 'Completed') {
            alert("Payment is already completed for this order.");
            return;
        }
        try {
            const paymentResponse = await axios.post(`${apiUrl}/api/payment/initiate`, {
                orderId: orderId,
                amount: order.totalPrice
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            if (paymentResponse.status === 200) {
                const paymentLink = paymentResponse.data.paymentLink;
                const customerId = paymentResponse.data.customerId;

                // Send the payment link to the specific customer via WebSocket
                sendPaymentLinkToCustomer(paymentLink, customerId);
            } else {
                throw new Error('Failed to initiate payment');
            }
        } catch (error) {
            console.error('Error initiating payment:', error);
            alert('Payment initiation failed: ' + (error.response?.data || error.message));
        }
    };





 const initiatePayment2 = async (orderId) => {
    const order = orders.find(o => o.id === orderId);
    if (!order) {
        alert("Order not found!");
        return;
    }
    if (order.paymentStatus === 'Completed') {
        alert("Payment is already completed for this order.");
        return;
    }

    try {
        const paymentResponse = await axios.post(`${apiUrl}/api/payment/initiate-upi-payment`, {
            orderId: orderId,
            amount: order.totalPrice
        }, {
            headers: { Authorization: `Bearer ${token}` }
        });

        if (paymentResponse.status === 200) {
            const paymentLink = paymentResponse.data.paymentLink;
            const customerId = paymentResponse.data.customerId; // Assuming you have customer's ID associated with the order

            // Send the UPI URI to the specific customer via WebSocket
            sendPaymentLinkToCustomer(paymentLink, customerId);

        } else {
            throw new Error('Failed to initiate UPI payment');
        }
    } catch (error) {
        console.error('Error initiating UPI payment:', error);
        alert('UPI payment initiation failed: ' + (error.response?.data || error.message));
    }
};


    useEffect(() => {
        const socket = new SockJS(`${apiUrl}/ws`);
        const client = new StompClient({
            webSocketFactory: () => socket,
            reconnectDelay: 5000,
            debug: (str) => console.log('STOMP Debug:', str),
            connectHeaders: {
                Authorization: `Bearer ${token}`
            }
        });

        client.activate();
        setStompClient(client);

        return () => {
            client.deactivate();
        };
    }, [token]);

    const sendPaymentLinkToCustomer = (paymentLink, customerId) => {
        console.log('Attempting to send payment link...');

        if (stompClient?.connected) {
            console.log('STOMP client already connected, sending payment link...');
            publishPaymentLink(paymentLink, customerId);
        } else {
            console.log('Connecting STOMP client...');
            stompClient.activate();
            stompClient.onConnect = () => {
                console.log('STOMP client connected, sending payment link...');
                publishPaymentLink(paymentLink, customerId);
            };
        }

        stompClient.onStompError = (error) => {
            console.error('STOMP Error:', error);
        };
    };

    const publishPaymentLink = (paymentLink, customerId) => {
        stompClient.publish({
            destination: `/app/sendPaymentLink`,
            body: JSON.stringify({ paymentLink, customerId })
        });
    };

    const updateOrderStatus = async (orderId, newStatus) => {
        try {
            const response = await axios.patch(`${apiUrl}/api/orders/${orderId}/status/${newStatus}`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            });
            console.log('Order status updated:', response.data);
            const updatedOrders = orders.map(order =>
                order.id === orderId ? { ...order, status: newStatus } : order
            );
            setOrders(updatedOrders);
        } catch (error) {
            console.error('Error updating order status:', error);
            alert('Failed to update order status: ' + (error.response?.data || 'Unknown error'));
        }
    };
  const getPaymentStatusClass = (paymentStatus) => {
          switch (paymentStatus) {
              case 'Paid':
                  return styles.paymentStatusPaid;
              case 'Pending':
                  return styles.paymentStatusPending;
              case 'Failed':
                  return styles.paymentStatusFailed;
              default:
                  return styles.paymentStatusPending; // Default to Pending for undefined statuses
          }
      };
  if (loading) {
    return <LoadingSpinner />;
  }
    if (error) return <div className={styles.error}>Error: {error}</div>;
    if (orders.length === 0) return <div className={styles.noOrders}>No orders found.</div>;
return (
    <div className={styles.ordersContainer}>
        <h1 className={styles.ordersHeader}>Orders</h1>
        <div className={styles.tableWrapper}>
            <table className={styles.ordersTable}>
                <thead>
                    <tr>
                        <th>Table No.</th>
                        <th>Items</th>
                        <th>Total Price</th>
                        <th>Payment Status</th>
                        {/* <th>Actions</th> */}
                         <th>UPI Payment</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>Ordered By</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map(order => (
                        <tr key={order.id}>
                            <td>{order.tableNumber}</td>
                            <td>
                                <ul className={styles.itemsList}>
                                    {order.items.map(item => (
                                        <li key={item.id} className={styles.productItem}>
                                            {item.productName} <span className={styles.quantityBadge}>Qty: {item.quantity}</span>
                                        </li>
                                    ))}
                                </ul>
                            </td>
                            <td>{order.totalPrice ? `${order.totalPrice.toFixed(2)}` : 'N/A'}</td>
                            <td>
                                <span className={`${styles.paymentStatusBadge} ${getPaymentStatusClass(order.paymentStatus)}`}>
                                    {order.paymentStatus || 'Pending'}
                                </span>
                            </td>
                          {/*   <td>
                                 {order.paymentStatus !== 'Paid' && order.paymentStatus !== 'Completed' && (
                                    <button
                                        className={styles.initiatePaymentButton}
                                        onClick={() => initiatePayment(order.id)}
                                    >
                                        Initiate Payment
                                    </button>
                                )}
                            </td> */}


                             <td>
                                                             {order.paymentStatus !== 'Paid' && order.paymentStatus !== 'Completed' && (
                                                                <button
                                                                    className={styles.initiatePaymentButton}
                                                                    onClick={() => initiatePayment2(order.id)}
                                                                >
                                                                    Initiate Payment
                                                                </button>
                                                            )}
                                                        </td>
                            <td>{order.status}</td>
                            <td>{new Date(order.orderTime).toLocaleString()}</td>
                            <td>{order.userName ? order.userName : 'Unknown'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
);

}

export default OrdersPage;
