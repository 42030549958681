import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { QrReader } from 'react-qr-reader';
import './UpiPaymentSetup.css';
import LoadingSpinner from './LoadingSpinner';

function UpiPaymentSetup() {
    const { token } = useContext(AuthContext);
    const [upiId, setUpiId] = useState('');
    const [savedUpiId, setSavedUpiId] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [scannerActive, setScannerActive] = useState(false);
    const [confirmSave, setConfirmSave] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchUpiId = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/business/account`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setSavedUpiId(response.data.upiId || '');
            } catch (error) {
                console.error('Failed to fetch UPI ID:', error);
                setError('Failed to fetch UPI ID.');
            }
        };

        fetchUpiId();
    }, [token, apiUrl]);

    const handleResult = (result, error) => {
        if (result) {
            const data = result.text;
            if (data.startsWith("upi://")) {
                const url = new URL(data);
                const pa = url.searchParams.get('pa');
                setUpiId(pa);
                setScannerActive(false);
                setError('');
            } else {
                setError("Please scan a valid UPI QR code.");
            }
        } else if (error) {
            console.error('Scanning error:', error);
            setError('Error scanning QR Code: ' + error.message);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!confirmSave) {
            setError('Please confirm to save the UPI ID.');
            return;
        }
        setLoading(true);
        console.log("Sending UPI ID:", upiId);

        try {
            const response = await axios.post(`${apiUrl}/api/business/account`, { accountDetails: upiId }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                setSavedUpiId(upiId); // Update local state to reflect the newly saved UPI ID
                alert('UPI ID saved successfully!');
                setError('');
                setUpiId(''); // Clear extracted UPI ID
                setConfirmSave(false); // Reset checkbox
            } else {
                throw new Error('Failed to save UPI ID');
            }
        } catch (err) {
            setError('Failed to save UPI ID: ' + err.message);
        } finally {
            setLoading(false);
        }
    };
  if (loading) {
    return <LoadingSpinner />;
  }
    return (
        <div className="upi-payment-container">
            <h2 className="upi-payment-header">Setup UPI Payment</h2>
            {savedUpiId && <p className="saved-upi">Saved UPI ID: {savedUpiId}</p>}
{/*
            {error && <p className="error-message">{error}</p>}
 */}
            <form onSubmit={handleSubmit} className="form-group">
                {!upiId && !scannerActive && <label className="form-label">Scan UPI QR Code:</label>}
                {scannerActive && (
                    <div className="qr-reader-container">
                        <QrReader
                            delay={300}
                            onError={(e) => setError('Scanning error: ' + e.message)}
                            onResult={handleResult}
                            className="qr-reader"
                            constraints={{ facingMode: 'environment' }}
                        />
                    </div>
                )}
                {upiId && (
                    <>
                        <p className="center">Extracted UPI ID: {upiId}</p>
                        <div className="confirm-group">
                            <input
                                type="checkbox"
                                id="confirmSave"
                                checked={confirmSave}
                                onChange={(e) => setConfirmSave(e.target.checked)}
                            />
                            <label htmlFor="confirmSave">Confirm to save UPI ID and start receiving payments.</label>
                        </div>
                    </>
                )}
                <div className="button-group">
                    <button type="button" onClick={() => setScannerActive(!scannerActive)} className="button">
                        {scannerActive ? 'Stop Scanner' : 'Start Scanner'}
                    </button>
                    <button type="submit" disabled={loading || !upiId || !confirmSave} className="button">Save UPI ID</button>
                </div>
            </form>
        </div>
    );
}

export default UpiPaymentSetup;
