import React from 'react';
import { useNavigate } from 'react-router-dom';

const OrderSuccess = () => {
    const navigate = useNavigate();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', textAlign: 'center' }}>
            <h1>Order Placed Successfully!</h1>
            <p>Your order has been successfully placed. You can view your order details or return to the home page.</p>
            <button onClick={() => navigate('/OrdersPage')} style={{ padding: '10px 20px', margin: '10px', fontSize: '16px' }}>View Order Details</button>
            <button onClick={() => navigate('/')} style={{ padding: '10px 20px', margin: '10px', fontSize: '16px' }}>Return Home</button>
        </div>
    );
};

export default OrderSuccess;
