
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { NotificationProvider } from './context/NotificationContext'; // Import NotificationProvider
import { WebSocketProvider } from './context/WebSocketContext'; // Assuming you have this from earlier discussions
import NotificationDisplay from './components/NotificationDisplay';

// Components
import Home from './components/Home';
import RegistrationForm from './components/RegistrationForm';
import NotFound from './components/NotFound';
import Login from './components/Login';
import Navigation from './components/Navigation';
import './components/Navigation.css';
import React, {useEffect, useContext } from 'react';
import { useAuth } from './context/AuthContext'; // Import useAuth here
import { setupAxiosInterceptors } from './axiosConfig';
import AuthContext from './context/AuthContext';
import MenuImageUpload from './components/MenuImageUpload';
import QRCodeComponent from './components/QRCodeComponent';
import MenuViewer from './components/MenuViewer'; // Import your Menu Viewer component
import MenuViewer1 from './components/MenuViewer1'; // Import your Menu Viewer component
import MenuManagement from './components/MenuManagement'; // Import your Menu Viewer component

import OrdersPage from './components/OrdersPage'; // Import your Menu Viewer component
import OtpVerification from './components/OtpVerification'; // Import your Menu Viewer component
import BusinessRegistrationForm from './components/BusinessRegistrationForm'; // Import your Menu Viewer component
import OrderSuccess from './components/OrderSuccess'; // Import your Menu Viewer component
import CustomerOrdersPage from './components/CustomerOrdersPage'; // Import your Menu Viewer component
import BusinessPaymentSetup from './components/BusinessPaymentSetup'; // Import your Menu Viewer component
import CustomerPaymentSetup from './components/CustomerPaymentSetup'; // Import your Menu Viewer component
//import WaitstaffService from './components/WaitstaffService'; // Import your Menu Viewer component
import WaiterService from './components/WaiterService'; // Import your Menu Viewer component
import GroupOrderComponent from './components/GroupOrderComponent'; // Import your Menu Viewer component
import { GoogleOAuthProvider } from '@react-oauth/google';
import QrCodeScanner from './components/QrCodeScanner';
import UpiPaymentSetup from './components/UpiPaymentSetup';


// Contexts
const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;


function App() {
  const auth = useAuth();

    const { token } = useContext(AuthContext);
/*
      useEffect(() => {
        if (token) {
          setupAxiosInterceptors(token);
        }
      }, [token]);
 */


/*
    useEffect(() => {
      if (token) {
        setupAxiosInterceptors(token);
      }
    }, [token]);
 */


 const isBusinessOwner = () => {
    return auth.user?.authorities?.some(auth => auth.authority === 'BUSINESS_OWNER');
  };
  return (<GoogleOAuthProvider clientId={clientId}>
    <NotificationProvider>
          <WebSocketProvider> {/* Ensure WebSocketProvider wraps your Routes if you're using it */}

      <Router>
        <Navigation />
        <div className="container" style={{ padding: '20px' }}>
                        <NotificationDisplay />


          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
             <Route path="/MenuImageUpload" element={<MenuImageUpload />} />
             <Route path="/qr-scan" element={<QrCodeScanner />} />
             <Route path="/QRCodeComponent" element={<QRCodeComponent />} />
           {/* <Route path="/menus" element={<MenuViewer />} /> */} {/* Updated to handle query params */}
                        <Route path="/menus" element={<MenuViewer1 />} />  {/* Updated to handle query params */}
                        <Route path="/MenuManagement" element={<MenuManagement />} />  {/* Updated to handle query params */}
                        <Route path="/UpiPaymentSetup" element={<UpiPaymentSetup />} />  {/* Updated to handle query params */}


             <Route path="/OrdersPage" element={<OrdersPage />} />
             <Route path="/order-success" element={<OrderSuccess />} />
             <Route path="/CustomerOrdersPage" element={<CustomerOrdersPage />} />
             <Route path="/BusinessPaymentSetup" element={<BusinessPaymentSetup />} />
             <Route path="/CustomerPaymentSetup" element={<CustomerPaymentSetup />} />



             <Route path="/WaiterService" element={<WaiterService />} />

            <Route path="/registration" element={<RegistrationForm />} />
            <Route path="/BusinessRegistrationForm" element={<BusinessRegistrationForm />} />
            <Route path="/otp-verify" element={<OtpVerification />} />
            <Route path="*" element={<NotFound />} />
                    <Route path="/scan" element={<QrCodeScanner />} />

          </Routes>
        </div>
      </Router>
            </WebSocketProvider>

    </NotificationProvider>    </GoogleOAuthProvider>
  );
}

export default App;