import React from 'react';
import styles from './Modal.module.css'; // Make sure to create a corresponding CSS file or use inline styles

function Modal({ children, onClose }) {
    return (
        <div className={styles.modalBackground}>
            <div className={styles.modalContent}>
                <button onClick={onClose} className={styles.closeButton}>X</button>
                {children}
            </div>
        </div>
    );
}

export default Modal;
